<template>
    <div class="main">
        <div class="main-title">注册成功！欢迎你的加入~</div>
        <div class="main-img">
            <div class="image"></div>
            <div class="image-bottom">
                微信扫一扫，体验小程序
            </div>
        </div>
        <div class="main-rz" @click="otherPage()">先去认证~</div>
    </div>
</template>
<script>

export default {
    data() {
        return {}
    },
    methods: {
        otherPage() {
            this.$router.push('/shipper/rz')
        }
    }
}
</script>
<style lang="css" scoped>
.main {
    position: absolute;
    top: 150px;
    right: 15%;
    background: white;
    height: 400px;
    width: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 30px;
    align-items: center;
}   
.main-title {
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    /* text-align: left; */
    line-height: 50px;
}
.main-img {
    height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.main-img .image {
    height: 160px;
    width: 160px;
    border: 1px solid;
    background: url(../../image/code.png) no-repeat;
    background-size: 100% 100%;
}
.main-img .image-bottom {
    font-size: 16px;
    font-weight: bold;
}
.main-rz {
    height: 40px;
    width: 100%;
    background: rgb(3, 93, 252);
    line-height: 40px;
    border-radius: 25px;
    color: white;
}
</style>